import {Box, Button, Container, Divider, Typography} from "@material-ui/core";
import * as React from "react";
import {LocationOn} from "@mui/icons-material";
import {primaryColor} from './const/consts'

export default function Company() {
    return (
        <Box id="company" sx={{ textAlign: "left", p: 2, display: "flex", alignItems: "center", bgcolor: "#ffffff" }}>
            <Container>
                <Box sx={{ m: 5, }}>
                    <Typography variant="h2" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                        Company
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                        회사개요
                    </Typography>
                </Box>
                <CompanySubtitle title="회사명" body="주식회사 브릿지코드"/>
                <CompanySubtitle title="사업내용" body="데이터 분석 기반, 데이터레이크 등의 데이터 관련 서비스 구축 및 지원, 웹 및 모바일 애플리케이션 개발 및 데이터 전송, 게임 운영 및 퍼블리싱, 컨설팅 등"/>
                <CompanySubtitle title="대표" body="대표이사 고성환"/>
                <CompanySubtitle title="설립" body="2024년 1월 8일"/>
                <CompanySubtitle title="MAIL" body="info@bridgecords.com"/>
                <CompanySubtitle title="소재지" body="〒150-0011 경기도 성남시 분당구 성남대로 51" body2="467호 (구미동, 분당포스빌오피스텔)" isAddressButtonEnable="true"/>
            </Container>
        </Box>
    );
}

function CompanySubtitle(props) {
    return (
        <Box sx={{ m: 5, }}>
            <Typography variant="body2" gutterBottom component="div" style={{ color: primaryColor, }}>
                {props.title}
            </Typography>
            <Typography variant="body1" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                {props.body}<br/>
                {props.body2}
            </Typography>
            {props.isAddressButtonEnable ? <AddressGoogleMapButton/> : ''}
            <Divider/>
        </Box>
    );
}

function AddressGoogleMapButton() {
    const googleMapUrl = "https://maps.app.goo.gl/bikURFHnnc5Qaskw8";

    return (
        <Box sx={{ paddingBottom: 15 }}>
            <Button style={{backgroundColor: "black", color: "white"}} variant="contained" startIcon={<LocationOn/>} onClick={(e) => {
                window.open(googleMapUrl, '_blank')
                e.preventDefault();
            }}>Google Map</Button>
        </Box>
    );
}