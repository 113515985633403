import {Box, Container, Typography} from "@material-ui/core";
import * as React from "react";

export default function Footer() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

    return(
        <Box sx={{ height: 30, width: "auto", textAlign: "center", p: 3, display: "flex", alignItems: "center",}} style={{ backgroundColor: "black"}}>
            <Container>
                <Box>
                    <Typography variant="subtitle2" gutterBottom component="div" style={{ color: "white", fontWeight: "bold" }}>
                      © {year} BridgeCords Co.,LTD
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}