import './App.css';
import Header from "./components/common/Header";
import * as React from "react";
import Footer from "./components/common/Footer";
import About from "./components/About";
import Service from "./components/Service";
import Company from "./components/Company";
import Contact from "./components/Contact";

function App() {
    return (
        <div className="App">
            <Header/>
            <About/>
            <Service/>
            <Company/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;
