import {Box, Container, Grid, Typography} from "@material-ui/core";
import * as React from "react";
import {primaryColor} from './const/consts'
import bgImageUrl from "../assets/sub-bg-image.jpeg"

const style = {
    serviceImage: {
        backgroundImage: `url(${bgImageUrl})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
    },
}

export default function Service() {
    return (
        <Box id="service" sx={{ flexGrow: 1, p: 2, textAlign: "left", display: "flex", bgcolor: "#fafafa" }} alignItems="center">
            <Container>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item style={{ maxWidth: 700, }}>
                                <Box sx={{ m: 5, }}>
                                    <Typography variant="h2" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                                        Service
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                                        사업내용
                                    </Typography>
                                </Box>
                                <Box sx={{ m: 5, }}>
                                    <Typography variant="subtitle2" gutterBottom component="div" style={{ color: primaryColor, fontWeight: "bold" }}>
                                        Develop
                                    </Typography>
                                    <Typography variant="h4" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                                        Data infrastructure<br/>
                                        Web Application<br/>
                                        Mobile Application<br/>
                                    </Typography>
                                </Box>
                                <Box sx={{ m: 5, }}>
                                    <Typography variant="subtitle2" gutterBottom component="div" style={{ color: primaryColor, fontWeight: "bold" }}>
                                        Management
                                    </Typography>
                                    <Typography variant="h4" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                                        Game Publishing<br/>
                                        Game Management<br/>
                                    </Typography>
                                </Box>
                                <Box sx={{ m: 5, }}>
                                    <Typography variant="body1" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                                        지속적으로 변화하는 업계의 흐름을 선도하며, 최신 기술과 전문 지식을 통해 세계적인 IT 기술의 중심으로 자리매김하는 것이 우리의 사명입니다. 우리는 글로벌 IT 테크놀로지를 연결하는 가교가 되어, 미래를 향한 혁신의 길을 개척하겠습니다.
                                        <br/>
                                        <br/>
                                        저희는 모바일 및 WEB3 게임 운영 및 퍼블리싱 분야에서 축적된 풍부한 경험을 바탕으로 다양한 게임 개발 프로젝트에 참여하고 있습니다. 고객의 요구사항을 정확히 파악하고, 이에 부합하는 최적의 솔루션과 서비스를 제공함으로써, 각 프로젝트의 성공을 위해 최선을 다하고 있습니다.
                                        <br/>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item style={{ maxWidth: 700, }}>
                                <Box sx={{ width: [300, 500], height: [300, 650]}} style={style.serviceImage}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}