import {Box, Button, Container, Grid, Typography} from "@material-ui/core";
import MailIcon from "@mui/icons-material/Mail";
import * as React from "react";

export default function Contact() {
    return (
        <Box id="contact" sx={{ flexGrow: 1, textAlign: "left", display: "flex" }} style={{ backgroundColor: "#fafafa", height: 350 }} alignItems="center">
            <Container>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item>
                                <Box sx={{ p: 2 }}>
                                    <Typography variant="h4" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                                        Contact
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                                        문의사항
                                    </Typography>
                                    <Typography variant="body2" gutterBottom component="div" style={{ color: "inherit", fontWeight: "bold" }}>
                                        BridgeCords는 협업 파트너 및 함께 일할 인재를 모집하고 있습니다.<br/>
                                        신규 사업 개발 및 채용에 대한 연락은 이쪽의 이메일 주소로 보내주세요.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={{ width: [0, 300] }} />
                            </Grid>
                            <Grid item>
                                <Box sx={{ p: 2 }}>
                                    <Button style={{backgroundColor: "white"}} variant="contained" startIcon={<MailIcon/>} onClick={(e) => {
                                        window.location.href = "mailto:info@bridgecords.com";
                                        e.preventDefault();
                                    }}>info@bridgecords.com</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}