import logo from '../../assets/BridgeCords_LOGO.svg'
import {AppBar, Box, Button, IconButton, ListItemButton, Toolbar, Typography, useMediaQuery} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {primaryColor} from '../const/consts'

const useSize = () => useMediaQuery('(min-width:600px)');
const style = {
    header: {
        position: "fixed",
        top: 0,
        width: "100%",
        backgroundColor: "white",
    }
}

export default function Header() {
    return (
        <header className="Header" style={style.header}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" color="transparent">
                    <Toolbar>
                        <Box sx={{ width: 35, height: "auto", m: 1}}>
                            <a href="#about"><img src={logo} className="logo__horizon" alt="Home" width="35"/></a>
                        </Box>
                        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                            <a href="#about" style={{textDecoration: "none", color: primaryColor}}>BridgeCords</a>
                        </Typography>
                        {useSize() ? <CreateDesktopMenu/> : <CreateMobileMenu/>}
                    </Toolbar>
                </AppBar>
            </Box>
        </header>
    );
}

function CreateDesktopMenu() {
    return (
        <Box>
            <Button
                size="medium"
                edge="start"
                color="inherit"
                sx={{ mr: 2 }}
                href="#about"
                style={{ color: primaryColor }}
            >ABOUT
            </Button>
            <Button
                size="medium"
                edge="start"
                color="inherit"
                sx={{ mr: 2 }}
                href="#service"
                style={{ color: primaryColor }}
            >SERVICE
            </Button>
            <Button
                size="medium"
                edge="start"
                sx={{ mr: 2 }}
                href="#company"
                style={{ color: primaryColor }}
            >COMPANY
            </Button>
            <Button
                size="medium"
                edge="start"
                sx={{ mr: 2 }}
                href="#contact"
                style={{ color: primaryColor }}
            >CONTACT
            </Button>
        </Box>
    )
}

function CreateMobileMenu() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const menus = ['ABOUT', 'SERVICE', 'COMPANY', 'CONTACT']

    const list = (anchor) => (
        <Box
            sx={{ width: '100%' }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List sx={{ p: 2, }}>
                {menus.map((text, index) => (
                    <ListItem key={text} component="a" href={`#${text.toLowerCase()}`} disablePadding style={{textDecoration: "none", color: primaryColor,}}>
                        <ListItemButton>
                            <ListItemText style={{ fontWeight: "bold" }} primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <div>
            <React.Fragment key='bottom'>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={toggleDrawer('bottom', true)}
                >
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                    anchor='bottom'
                    open={state['bottom']}
                    onClose={toggleDrawer('bottom', false)}
                    onOpen={toggleDrawer('bottom', true)}
                >
                    {list('bottom')}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}