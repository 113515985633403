import {Box, Container, Typography} from "@material-ui/core";
import * as React from "react";

import bgImage from '../assets/main-bg-image.jpg'

const styles = {
    containerImage: {
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
    containerBox: {
        display: "flex",
        backgroundColor: "rgba(255, 255, 255, 0.0)",
        height: "100%",
        width: "100%",
    }
}

export default function About() {
    return (
        <Box id="about" sx={{ height: [500, 1000], width: "auto",}} style={styles.containerImage}>
            <Box style={styles.containerBox} sx={{ width: "auto", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Container>
                    <Typography variant="h3" gutterBottom component="div" style={{color: "black", fontWeight: "bold"}}>
                        BridgeCords<br/>
                        세상을 잇는 IT, 미래 창조의 동반자입니다
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
}